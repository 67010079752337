<template>
  <div class="404">
    <Nav></Nav>
    <section class="max-w-7xl px-4 mx-auto min-h-screen text-center flex flex-col items-center py-24">
      <h1 class="font-bold text-2xl md:text-4xl tracking-wide">
        Ops, Page Not Found
      </h1>
      <p class="text-sm md:text-base text-gray-800">
        It seems you have wandered off to the land of 404.
      </p>
      <img style="width:450px"
           class="mt-20"
           :src="require('@/assets/img/notfound.svg')" alt="404 Not Found"/>
    </section>

    <Footer></Footer>
  </div>
</template>
<script>
import Nav from "@/views/components/Nav";
import Footer from "@/views/components/Footer";
export default {
  components: {Footer, Nav}
}
</script>